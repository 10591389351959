import useTranslation from 'next-translate/useTranslation'
import React from 'react'

function FallbackError() {
  const { t, lang } = useTranslation('client')
  return (
    <div className="flex justify-center items-center text-center w-full flex-col gap-2">
      <img width="71" height="auto" src="/error.svg" className="max-w[160px] max-h[160px]"></img>
      <p className="text-lg font-medium text-gray-900">{t('noResults')}</p>
    </div>
  )
}

export default FallbackError
