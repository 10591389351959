/* eslint-disable */
import {
  FindManyBlogsDocument,
  ListCoursesDocument,
} from '@/types/graphql/types'
import { useQuery } from '@apollo/client'
import { GlobeAltIcon, WifiIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { EVENT_FOLDER, METHOD_OF_DELIVERY } from 'db'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import GradientButtonLink from '../components/button/GradientButtonLink'
import InfinityLoader from '@/components/common/infinityLoader'
import FallbackError from '@/components/common/error/FallbackError'

const bgColors = [
  'bg-cyan-100 text-cyan-800',
  'bg-yellow-100 text-yellow-800',
  'bg-red-100 text-red-800',
  'bg-red-100 text-red-800',
  'bg-blue-100 text-blue-800',
  'bg-indigo-100 text-indigo-800',
  'bg-purple-100 text-purple-800',
  'bg-pink-100 text-pink-800',
]

require('dayjs/locale/ar')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Riyadh')

const LatestBlogs = () => {
  const { t, lang } = useTranslation('client')
  const isArabic = lang === 'ar'
  const { data, loading } = useQuery(FindManyBlogsDocument, {
    variables: {
      take: 3,
      where: {},
    },
  })
  // if (!data) return null
  return (
    <section className="py-16 sm:py-24 lg:py-32">
      <div className="">
        <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">
            {t('learn')}
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            {t('latestBlogs')}
          </p>
          <p className="mt-5 mx-auto max-w-prose text-xl text-gray-500">
            {t('latestBlogsDescription')}
          </p>
        </div>
        {(data?.findManyBlogs?.length || 0) > 0 ? (
          <>
            <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
              {data?.findManyBlogs?.map(blog => (
                <div
                  key={blog!.id}
                  className="flex flex-col rounded-lg shadow-xl overflow-hidden relative"
                >
                  <div className="flex-shrink-0">
                    <img
                      className="h-48 w-full object-cover"
                      src={
                        blog!.thumbnailImage ||
                        '/app-images/schedule-background.png'
                      }
                      alt={blog!.title!}
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <Link href={`/blogs/${blog?.id}`}>
                        <a className="block mt-2">
                          <span className="absolute inset-0" />
                          <p className="text-xl font-semibold text-gray-900">
                            {blog!.title}
                          </p>
                          <div className="flex space-x-1 text-sm text-gray-500">
                            <p>
                              {t('date')}
                              {'  '}
                              <time dateTime={blog?.createdAt}>
                                {dayjs(blog?.createdAt).format(
                                  'dddd, MMMM DD, YYYY',
                                )}
                              </time>
                            </p>
                          </div>
                          <p
                            className="mt-3 text-base text-gray-500"
                            dangerouslySetInnerHTML={{
                              __html:
                                blog!.description?.replaceAll(
                                  'style="color: rgb(13, 13, 13);"',
                                  '',
                                ) ?? '',
                            }}
                          />
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center py-8">
              <GradientButtonLink herf="/blogs">
                {t('exploreAllBlogs')}
              </GradientButtonLink>
            </div>
          </>
        ) : loading ? (
          <div className="flex justify-center items-center">
            <InfinityLoader variant="md" />
          </div>
        ) : (
          <div className="my-9">
            <FallbackError />
          </div>
        )}
      </div>
    </section>
  )
}

export default LatestBlogs
