/* eslint-disable @next/next/no-img-element */
import React, { useRef } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

const Carousel = ({ images }: { images: Array<string> }) => {
  const autoplay = useRef(Autoplay({ delay: 10000, stopOnInteraction: true }))

  const [emblaRef] = useEmblaCarousel({ loop: true, speed: 2 }, [
    autoplay.current,
  ])
  //object-fill
  return (
    <div className="embla__viewport" ref={emblaRef}>
      <div className="embla__container">
        {images.map(image => (
          <div className="embla__slide" key={image}>
            <img
              className="h-full w-full object-fill"
              src={image}
              alt="medical students in saudi arabia"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Carousel
