import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

const MemberNeedsMainPageSection = () => {
  const { t } = useTranslation('client')
  return (
    <section className="bg-white py-16 sm:py-24">
      <div className="relative sm:py-16">
        <div aria-hidden="true" className="hidden sm:block">
          <div
            className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23164e63' fill-opacity='0.24' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
            }}
          />
          <svg
            className="absolute top-8 left-1/2 -ml-3"
            width={404}
            height={392}
            fill="none"
            viewBox="0 0 404 392"
          >
            <defs>
              <pattern
                id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={392}
              fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
            />
          </svg>
        </div>
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative rounded-2xl px-6 py-10 bg-secondary overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div
              aria-hidden="true"
              className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
            >
              <svg
                className="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  className="text-primary text-opacity-40"
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                />
                <path
                  className="text-cyan-700 text-opacity-40"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                />
              </svg>
            </div>
            <div className="relative text-center">
              <div className="pb-8">
                <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                  {t('requestTitle')}
                </h2>
                <div className="mt-6 mx-auto max-w-2xl text-cyan-100">
                  <p className="text-2xl">{t('requestCourseT1')}</p>
                  <p className="text-sm pb-4">{t('requestCourseST1')}</p>
                  <p className="text-2xl">{t('requestCourseT2')}</p>
                  <p className="text-sm pb-4">{t('requestCourseST2')}</p>
                  <p className="text-2xl">{t('requestCourseT3')}</p>
                  <p className="text-sm pb-4">{t('requestCourseST3')}</p>
                </div>
              </div>

              <Link href="/profile?i=2">
                <a className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary bg-opacity-60 hover:bg-opacity-70 sm:px-8">
                  {t('requestCourse')}
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MemberNeedsMainPageSection
