
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import BecomeTrainerMainPageSection from '@/components/client/becomeTraner/BecomeTrainer'
import ContactUsForm from '@/components/client/contact/ContactUsForm'
import UpcomingCourses from '@/components/client/eventCard/UpcomingCourses'
import Hero from '@/components/client/layout/Hero'
import ClientLayout from '@/components/client/layout/Index'
import MemberNeedsMainPageSection from '@/components/client/memberNeeds/MainPageSection'
import Statistics from '@/components/client/stats/Statistics'
import {
  FindHeroImagesDocument,
  ListCoursesDocument,
} from '@/types/graphql/types'
import { EVENT_FOLDER, SETTINGS_IDS } from 'db'
import { initializeApollo } from 'lib/apolloClient'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import { NextSeo } from 'next-seo'
import { useQuery } from '@apollo/client'
import ClientOnly from '@/components/common/ClientOnly'
import WhatsApp from '@/components/client/contact/WhatsApp'
import Balloons from '@/components/common/Balloons'
import LatestBlogs from '@/components/client/blogCard/LatestBlog'

const WorkInProgress = () => {
  const { t, lang } = useTranslation('client')
  const isArabic = lang === 'ar'

  const { data } = useQuery(FindHeroImagesDocument)
  const heroImages = (data?.findHeroImages?.find(
    item => item?.id === SETTINGS_IDS.heroImages.index,
  )?.value?.indexImages ?? []) as Array<string>

  return (
    <>
      <NextSeo
        canonical={`https://infinity.edu.sa/${isArabic ? 'ar/' : ''}`}
        openGraph={{
          images: heroImages.map(image => ({ url: image })),
        }}
      />
      <Head>
        <title>{t('mainPageTitle')}</title>
      </Head>
      <main>
        <Hero />
        <ClientOnly>
          <UpcomingCourses />
          <LatestBlogs />
        </ClientOnly>
        <Statistics />
        <MemberNeedsMainPageSection />
        <BecomeTrainerMainPageSection />
        <ContactUsForm />
        <WhatsApp />
        <Balloons />
      </main>
    </>
  )
}

 async function _getStaticProps() {
  const apolloClient = initializeApollo()

  await Promise.all([
    apolloClient.query({
      query: ListCoursesDocument,
      variables: {
        take: 3,
        where: {
          folderId: { equals: EVENT_FOLDER.published.id },
        },
      },
    }),
    apolloClient.query({
      query: FindHeroImagesDocument,
    }),
  ]).catch(() => {
    return {
      redirect: {
        destination: '/500',
      },
    }
  })

  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
    },
    revalidate: 10,
  }
}

WorkInProgress.Layout = ClientLayout

export default WorkInProgress


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  